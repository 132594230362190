import React from 'react'
import Layout from '../layouts/layout'
import Formularz from '../components/Formularz'
import styled from 'styled-components'

import banerjpg from '../images/headerPhotos/fachowiec.jpg'

const career = ({data}) =>{
  return(
    <Layout
      headerPhoto={<img src={banerjpg} alt="home"/>}
    >
      <h2>Chcesz pracować z nami?</h2>
      <p>Wypełnij formularz, a my odezwiemy się do Ciebie.</p>
      <FormWrapper>
        <Formularz imie nazwisko mail telefon zgoda/>
      </FormWrapper>
      </Layout>
    );
}

export default career

const FormWrapper = styled.div`
  display:flex;
  justify-content: center;
  margin-bottom:20px;
`